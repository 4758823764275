import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { createPrivacyPolicy, updatePrivacyPolicy } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import SelectField from "../../components/Select/Select";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {

  const privacyTypes = {
    text: {
      value: "Text",
      label: "Text",
    },
    link: {
      value: "Link",
      label: "Link"
    }
  }
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    caption: "",
    type: privacyTypes.text,
    link: "",
  });

  const [errors, setErrors] = useState({
    title: "",
    caption: "",
    type: "",
    link: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
    if (!data?.id) {
      setFormData({
        title: "",
        caption: "",
        type: privacyTypes.text,
        link: "",
      })
      setErrors({
        title: "",
        caption: "",
        type: "",
        link: "",
      })
    }
  }, [open]);
  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = {
        title: formData?.title?.trim() !== "" ? formData?.title?.trim() : null,
        caption: formData?.caption?.trim() !== "" ? formData?.caption?.trim() : null,
        type: formData?.type?.value?.trim() !== "" ? formData?.type?.value?.trim() : null,
        link: formData?.link?.trim() !== "" ? formData?.link?.trim() : null,
      };
      let tempErrors = {};

      if (!cleanData.type) {
        tempErrors["caption"] = "Type is required";
      } else {
        if (cleanData.type === "Text") {
          if (!cleanData.caption) {
            tempErrors["caption"] = "Caption is required";
          }
          if (!cleanData.title) {
            tempErrors["title"] = "Title is required";
          }
        } else if (cleanData.type === "Link") {
          if (!cleanData.link) {
            tempErrors["link"] = "Link is required"
          }
        }
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if(cleanData.type === "Text") {
        cleanData = {
          type: cleanData.type,
          title: cleanData.title,
          caption: cleanData.caption
        }
      } else if (cleanData.type === "Link") {
        cleanData = {
          type: cleanData.type,
          link: cleanData.link
        }
      }
      if (data?.id) {
        const response = await updatePrivacyPolicy(data?.id, cleanData);
        toast.success(response.data.message)
      } else {
        const response = await createPrivacyPolicy(cleanData);
        toast.success(response.data.message)
      }
      setOpen(false);
      setFormData({
        title: data?.title || "",
        caption: data?.caption || "",
        link: data?.link || "",
        type: privacyTypes.text,
        file: "",
      });
      setErrors({
        title: "",
        caption: "",
        link: "",
        type: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?.id) {
      setFormData({
        title: data?.title || "",
        caption: data?.caption || "",
        type: { value: data?.type || "", label: data?.type || "" },
        link: data?.link || "",
      });
    } else {
      setFormData({
        title: "",
        caption: "",
        type: privacyTypes.text,
        link: "",
      });
    }
  }, [data]);
  const fileHandler = useCallback(
    (event) => {
      if (event?.target?.files[0]) {
        setFormData({
          ...formData,
          file: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        });
      }
    },
    [formData]
  );

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
        ...(name === "type" && {
          title: data?.title ?? "",
          caption: data?.caption ?? "",
          link: data?.link ?? "",
        }),
      });
    },
    [data, formData]
  );

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?.id ? "Edit Privacy Policy" : "Add Privacy Policy"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>Type</label>
                <SelectField
                  name="type"
                  onChange={selectDropdownChangeHandler}
                  value={formData.type}
                  isMulti={false}
                  options={[
                    {
                      value: "Text",
                      label: "Text",
                    },
                    {
                      value: "Link",
                      label: "Link",
                    }
                  ]}
                />
                {errors.type && <p>{errors.type}</p>}
              </div>
              {
                formData.type.value === "Text" &&
                (
                  <>
                    <div className="input-wrapper">
                      <label>Title</label>
                      <input
                        name="title"
                        value={formData.title}
                        onChange={changeHandler}
                      />
                      {errors.title && <p>{errors.title}</p>}
                    </div>
                    <div className="input-wrapper">
                      <label>Caption</label>
                      <input
                        name="caption"
                        value={formData.caption}
                        onChange={changeHandler}
                      />
                      {errors.caption && <p>{errors.caption}</p>}
                    </div>
                  </>
                )
              }
              {
                formData.type.value === "Link" &&
                (
                  <>
                    <div className="input-wrapper">
                      <label>Link</label>
                      <input
                        name="link"
                        value={formData.link}
                        onChange={changeHandler}
                      />
                      {errors.link && <p>{errors.link}</p>}
                    </div>
                  </>
                )
              }

            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
