import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { activateDeactivateAdmin, getAdminNotifications } from '../../apis';
import { RxCrossCircled } from "react-icons/rx/index.esm";
import Pagination from '../../components/Pagination/Pagination';
import { bindActionCreators } from 'redux';
import actions from '../../actions';
import { connect } from 'react-redux';
import AddEdit from './AddEdit';

const AdminNotifications = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {

    const [data, setData] = useState({});
    const [pageInfo, setPageInfo] = useState({})
    const navigate = useNavigate()
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();
    const searchValue = searchParams.get('search') || '';

    const fetch = useCallback(async () => {
        try {
            const apiResponse = await getAdminNotifications(search);
            if (apiResponse?.data?.data?.notifications?.length > 0) {
                setData({ ...apiResponse?.data?.data });
                setPageInfo({ ...apiResponse?.data?.data?.pageInfo })
            } else {
                setData({});
            }
            stopLoadingRequest();
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.error(error.response.data.message);
                logoutRequest();
            } else if (error?.response?.status === 403) {
                toast.error(error.response.data.message);
            }
            stopLoadingRequest();
        }
    }, [data, pathname, search]);

    // useEffect(() => {
    //   startLoadingRequest();
    //   fetch();
    // }, [pathname]);

    const searchChangeHandler = useCallback((event) => {
        
        navigate(`/notifications?page=1&search=${encodeURIComponent(event.target.value)}`);
    }, []);

    const resetSearchHandler = useCallback(() => {
        navigate('/notifications?page=1&search=');
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            startLoadingRequest();
            fetch();
        }, 1000);
        return () => clearTimeout(timeout);
    }, [pathname, search]);


    return (
        <div className="section">
            <h1>Admin Notifications List</h1>
            <div className="wrapper">
                <div className="table-wrapper">
                    <div className="table-header">
                        <div className="search">
                            <div className="input-wrapper">
                                <input
                                    value={searchValue}
                                    onChange={searchChangeHandler}
                                    placeholder="Search..."
                                />
                                {searchValue && (
                                    <RxCrossCircled onClick={resetSearchHandler} size={18} />
                                )}
                            </div>
                        </div>
                         <AddEdit type={"notification-add"} fetch={fetch} />
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Scheduled At</th>
                                <th>Go Live Date & Time</th>
                                <th>isLive</th>
                                <th>Broadcast</th>
                                <th>Image</th>
                                <th>Users</th>
                                <th>Created By</th>
                                <th>Created At</th>
                            </thead>
                            <tbody>
                                {data?.notifications?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                                        <td>{item.title}</td>
                                        <td>{item.message}</td>
                                        <td>{item.scheduledAt ? new Date(item.scheduledAt).toLocaleString():  "NA"}</td>
                                        <td> {item.goLiveDateTime ? new Date(item.goLiveDateTime).toLocaleString(): 'NA'}</td>
                                        <td>{item.isLive ? "Yes" : "No"}</td>
                                        <td>{item.isBroadcast ? "Yes" : "No"}</td>
                                        <td>{ item.document?.id ? (<img src={item.document?.url}></img>) : "NA" }</td>
                                        <td>{ item.isBroadcast ? "ALL" : item.users.length }</td>
                                        <td>{item.admin?.firstName}</td>
                                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination pageInfo={data.pageInfo} />
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logoutRequest: actions.auth.logout,
            stopLoadingRequest: actions.loader.stopLoading,
            startLoadingRequest: actions.loader.startLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(AdminNotifications);