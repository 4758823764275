import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getUsers, activateDeactivateUser } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import ReasonModal from "./ReasonModal";
import { Tooltip } from "react-tooltip";
import AddEdit from "./AddEdit";
const Users = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false); // State to control modal visibility
  const [userToDeactivate, setUserToDeactivate] = useState(null); // Store the user to deactivate
  const [clickOn, setClickOn] = useState(0);
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search") || "";
  const [pageInfo, setPageInfo] = useState({})

  // const fetch = useCallback(async () => {
  //   try {
  //     const apiResponse = await getUsers(search);
  //     console.log(apiResponse)
  //     if (apiResponse?.data?.data?.users?.length > 0) {
  //       setData({ ...apiResponse?.data?.data });
  //       setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
  //     } else {
  //       setData({});
  //     }
  //     stopLoadingRequest();
  //   } catch (error) {
  //     if (error?.response?.status === 401) {
  //       toast.error(error.response.data.message);
  //       logoutRequest();
  //     } else if (error?.response?.status === 403) {
  //       toast.error(error.response.data.message);
  //     }
  //     stopLoadingRequest();
  //   }
  // }, [data, pathname, search]);

  const fetch = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(search);
      const currentPage = searchParams.get("page") || 1;
      const apiResponse = await getUsers(search);
      console.log(apiResponse);
      if (apiResponse?.data?.data?.users?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [search]); // Depend on 'search' to ensure consistency


  const activateDeactivateUserHandler = useCallback(
    async (id, requestType, reason) => {
      try {
        startLoadingRequest();
        await activateDeactivateUser(id, { requestType, reason }); // Send the reason along with the requestType
        // fetch();
        // Preserve the current page and refetch data
        const searchParams = new URLSearchParams(search);
        const currentPage = searchParams.get("page") || 1;
        fetch(`?page=${currentPage}&${searchParams.toString()}`);
        toast.success(
          requestType === 1
            ? "User activated successfully"
            : "User deactivated successfully"
        );
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    [fetch] // Add 'fetch' as a dependency
  );


  const searchChangeHandler = useCallback((event) => {
    navigate(`/users?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate("/users?page=1&search=");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  // Function to open the modal for deactivation
  const openModalHandler = (user) => {
    setUserToDeactivate(user); // Store the user info to deactivate
    setIsOpen(true); // Open the modal
  };

  const handleSubmitReason = (reason, ClickedOn) => {
    if (userToDeactivate) {
      activateDeactivateUserHandler(userToDeactivate.id, ClickedOn, reason); // Pass the reason to the handler
    }
  };
  return (
    <div className="section">
      <h1>Users</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={searchValue}
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {searchValue && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Onboarding date</th>
                <th>Code</th>
                <th>Name</th>
                <th>Phone number</th>
                <th>Email</th>
                <th>Reason for Activate/Deactivate</th>
                <th>Active/Inactive</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.users?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td>{item.createdAt}</td>
                    <td>{item.code}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {`${item.firstName} ${item.lastName}`}
                    </td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    {/* <td>{item.statusHistory[0]?.reason || "-"}</td> */}
                    <td>
                      <span
                        data-tooltip-id={`reason-tooltip-${item?._id || "default-id"}`}
                        data-tooltip-content={
                          Array.isArray(item?.statusHistory) && item?.statusHistory[0]?.reason
                            ? item.statusHistory[0].reason
                            : "-"
                        }
                      >
                        {Array.isArray(item?.statusHistory) && item?.statusHistory[0]?.reason
                          ? item.statusHistory[0].reason.slice(0, 40)
                          : "-"}
                      </span>
                      <Tooltip id={`reason-tooltip-${item?._id || "default-id"}`} place="top" />
                    </td>

                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive ? (
                          <>
                            <AddEdit data={item} fetch={fetch} />
                            <button
                              className="danger"
                              onClick={() => {
                                openModalHandler(item); // Open the modal
                                setClickOn(0); // Set the click action
                              }}
                            >
                              Deactivate
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() => {
                              openModalHandler(item); // Open the modal
                              setClickOn(1); // Set the click action
                            }}
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.users?.length > 0 && <Pagination pageInfo={data.pageInfo} />}
        </div>
      </div>
      {isOpen && (
        <ReasonModal
          onClose={() => setIsOpen(false)} // Close the modal
          userToDeactivate={userToDeactivate} // Pass user data to modal
          onSubmit={handleSubmitReason}
          clickOn={clickOn}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Users);
