import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getBanners, deleteBanner, makeBannerLive, activateBanner } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";
import { RxCrossCircled } from "react-icons/rx/index.esm";

const Admins = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [data, setData] = useState({});
  const [openDeleteBanner, setOpenDeleteBanner] = useState(false);
  const [bannerId, setBannerId] = useState(null);
  const [pageInfo, setPageInfo] = useState({})
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getBanners(search);
      if (apiResponse?.data?.data?.banners?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo })
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname]);
  const activateDeactivateBannerHandler = useCallback(async (id, isActive) => {
    try {
      startLoadingRequest();
      await activateBanner(id, {isActive});
      toast.success(
        !isActive
          ? "Banner activated successfully"
          : "Banner deactivated successfully"
      );
      const searchParams = new URLSearchParams(search);
      const currentPage = searchParams.get("page") || 1;
      fetch();
      // fetch(`?page=${currentPage}&${searchParams.toString()}`);
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [fetch]);
  const makeBannerLiveHandler = useCallback(async (id) => {
    try {
      startLoadingRequest();
      await makeBannerLive(id);
      toast.success("Banner is now live");
      fetch();
    } catch (error) {
      toast.error(error.response.data.message);
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      }
      stopLoadingRequest();
    }
  }, []);

  const deleteBannerHandler = useCallback((id) => {
    setOpenDeleteBanner(true);
    setBannerId(id);
  }, [])
  const deleteBannerById = useCallback(async (id) => {
    try {
      startLoadingRequest();
      await deleteBanner(id);
      toast.success("Banner deleted successfully");
      setOpenDeleteBanner(false);
      fetch();
    } catch (error) {
      toast.error(error.response.data.message);
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      }
      stopLoadingRequest();
    }
  }, [])


  const searchChangeHandler = useCallback((event) => {
    navigate(`/banners?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate('/banners?page=1&search=');
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  return (
    <div className="section">
      <h1>Banners</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={searchValue}
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {searchValue && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
            </div>
            <AddEdit type={"banner-add"} fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Image</th>
                <th>Code</th>
                <th>Title</th>
                <th>Caption</th>
                <th>Type</th>
                {/* <th>Live status</th>
                <th>Go live time</th>
                <th>Last action</th>
                <th>Last action time</th>
                <th>Last operation</th>
                <th>Last operation time</th> */}
                <th>Active/Inactive</th>
                <th>Created at</th>
                {/* <th>Last updated at</th> */}
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.banners?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td className="rect-image">
                      {item?.document?.url && <img src={item.document.url} />}
                    </td>
                    <td>{item.code}</td>
                    <td>{item.title}</td>
                    <td>{item.caption}</td>
                    <td>{item.type}</td>
                    {/* <td>{item.isLive ? "Live" : "Not Live"}</td>
                    <td>{item.goLiveDateTime}</td>
                    <td>{item.action?.description}</td>
                    <td>{item.action?.dateTime}</td>
                    <td>{item.operation?.type}</td>
                    <td>{item.operation?.dateTime}</td> */}
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    {/* <td>{item.updatedAt}</td> */}
                    <td>
                      <div className="table-actions">
                        {item?.isActive && (
                          <>
                            <AddEdit
                              type={`TnE-${item.id}`}
                              data={item}
                              fetch={fetch}
                            />
                            {/* {!item.isLive && (
                              <>
                                <button
                                  className="success"
                                  onClick={() =>
                                    makeTipAndTrickLiveHandler(item.id)
                                  }
                                >
                                  Publish
                                </button>
                              </>
                            )} */}
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateBannerHandler(
                              item.id,
                              !item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                        <button
                          className={"danger"}
                          // className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            deleteBannerHandler(item.id)
                            // activateDeactivateBannerHandler(
                            //   item.id,
                            //   item.isActive
                            // )
                          }
                        >
                          Delete
                          {/* {item.isActive ? "Deactivate" : "Activate"} */}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data.pageInfo} />
        </div>
      </div>
      {openDeleteBanner && (
        <div className="delete-popup">
          <div className="popup-content">
            <h2>Are you sure you want to delete this banner?</h2>
            <div className="popup-actions">
              <button
                className="success"
                onClick={() => deleteBannerById(bannerId)}
              >
                Confirm
              </button>
              <button className="danger"
                onClick={() => setOpenDeleteBanner(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Admins);
