import React, { useEffect, useRef, useState } from 'react'
import { IoMdClose, IoIosSend } from "react-icons/io";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_SOCKET_BASE_URL);

const Chat = (props) => {

  const { data } = useSelector((state) => state.auth);

  const [queryId, setQueryId] = useState(props.queryId);

  const messageRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  useEffect(() => {
    if (data?.id) {
      socket.emit("join-query-chat-request", {
        queryId,
        id: data?.id,
        type: "admin"
      })
    }
  }, [data])

  useEffect(() => {
    socket.on("roomJoined", (data) => {
      setMessages(data?.chats)
    });
    socket.on("messageRecieved", (data) => {
      setInputMessage("")
      setMessages((prev) => [...prev, data?.newMessage]);
    });
    socket.on("error", (data) => {
      toast.error(data?.message)
    })
  }, [socket])

  const submitHandler = (e) => {
    e.preventDefault();
    socket.emit("newMessage", {
      message: inputMessage,
      senderId: data?.id,
      type: "admin",
      queryId
    })
  }

  const scrollToBottom = () => {
    messageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToBottom();
    }, 0);

    return () => clearTimeout(timeout);
  }, [messages]);

  return (
    <div className="chat-closable-bg">
      <div className='chat-modal'>
        <div className="header">
          <span></span>
          <h3 className="title">{props.title}</h3>
          <IoMdClose size={20} onClick={() => props.resetModalInfo()} />
        </div>
        <div className="messages">
          {
            messages.map((message) => {
              return (
                <div className={`message-wrapper ${message?.adminId ? "right" : ""}`} key={message?.id}>
                  <div className='message'>
                    <p>{message?.message}</p>
                    <span>{message?.createdAt?.split(" ")}</span>
                  </div>
                </div>
              )
            })
          }
          <div className="message-ref" ref={messageRef}></div>
        </div>
        <div className="message-section">
          <input type="text" placeholder='Message ...' value={inputMessage} onChange={(e) => setInputMessage(e.currentTarget.value)} />
          <IoIosSend size={25} onClick={submitHandler} />
        </div>
      </div>
    </div>
  )
}

export default Chat