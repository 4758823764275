import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { createUser, updateUser } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    phoneNumber: data?.phoneNumber || "",
    email: data?.email || ""
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: ""
  });

  const openHandler = useCallback(() => {
    if (!data?.id) {
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: ""
      });
    }
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = {
        firstName: formData?.firstName?.trim(),
        lastName: formData?.lastName?.trim(),
        phoneNumber: formData?.phoneNumber?.trim(),
        email: formData?.email?.trim(),
        ...(!data?.id && { password: formData?.password?.trim() }),
      };
      let tempErrors = {};
      if (!cleanData.firstName) {
        tempErrors["firstName"] = "First Name is required";
      }
      if (!cleanData.lastName) {
        tempErrors["lastName"] = "Last Name is required";
      }
      if (!cleanData.phoneNumber) {
        tempErrors["phoneNumber"] = "Phone number is required";
      }
      if (!cleanData.email) {
        tempErrors["email"] = "Email is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?.id) {
        const response = await updateUser(data?.id, cleanData);
        toast.success(response?.data?.message);
      } else {
        const response = await createUser(cleanData);
        toast.success(response?.data?.message);
      }
      setOpen(false);
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: ""
      });
      setErrors({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: ""
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data?.id]);

  useEffect(() => {
    if (data?.id) {
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        phoneNumber: data?.phoneNumber || "",
        email: data?.email || ""
      });
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: ""
      });
    }
  }, [data]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?.id ? "Edit User" : "Add User"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>First Name</label>
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={changeHandler}
                />
                {errors.firstName && <p>{errors.firstName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Last Name</label>
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={changeHandler}
                />
                {errors.lastName && <p>{errors.lastName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Phone Number</label>
                <input
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={changeHandler}
                />
                {errors.phoneNumber && <p>{errors.phoneNumber}</p>}
              </div>
              <div className="input-wrapper">
                <label>Email</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
                {errors.email && <p>{errors.email}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
