import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueries, closeQuery } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";
import Pagination from "../../components/Pagination/Pagination";
import Chat from "../../components/Chat";

const HelpDesk = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({})
  const { pathname, search } = useLocation();
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    queryId: null
  })

  const resetModalInfo = () => {
    setModalInfo({
      isOpen: false,
      queryId: null
    })
  }

  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getQueries(search);
      if (apiResponse?.data?.data?.queries?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo })
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);

  const openChatModal = (queryId) => {
    setModalInfo({
      isOpen: true,
      queryId
    })
  }

  const closeQueryHandler = useCallback(async (id) => {
    try {
      await closeQuery(id);
      toast.success(
        "Query closed successfully"
      );
      const searchParams = new URLSearchParams(search);
      const currentPage = searchParams.get("page") || 1;
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [fetch])

  const searchChangeHandler = useCallback((event) => {
    navigate(
      `/helpdesk?page=1&search=${event.target.value}`
    );
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate(
      `/helpdesk?page=1&search=`
    );
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  return (
    <>
      {modalInfo.isOpen && <Chat title={`Ticket #${modalInfo.queryId?.split("").slice(modalInfo.queryId?.length > 7 ? modalInfo.queryId?.length - 6 : 0 ).join("")}`} queryId={modalInfo.queryId} resetModalInfo={resetModalInfo} />}
      <div className="section">
        <h1>Help Desk's</h1>
        <div className="wrapper">
          <div className="table-wrapper">
            <div className="table-header">
              {/* <div className="search">
          <div className="input-wrapper">
                  <input
                    value={
                      search
                        ?.split("?")[1]
                        ?.split("&")
                        ?.filter((el) => el.includes("search"))[0]
                        ?.split("=")[1]
                        ?.trim() || ""
                    }
                    onChange={searchChangeHandler}
                    placeholder="Search..."
                  />
                  {search
                    ?.split("?")[1]
                    ?.split("&")
                    ?.filter((el) => el.includes("search"))[0]
                    ?.split("=")[1]
                    ?.trim() && (
                    <RxCrossCircled onClick={resetSearchHandler} size={18} />
                  )}
                </div>
                </div> */}
              {/* <AddEdit fetch={fetch} /> */}
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <th>#</th>
                  <th>Subject</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Created at</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {data?.queries?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                      <td>{item.subject}</td>
                      <td>{item.message}</td>
                      <td>{item.status}</td>
                      <td>{new Date(item.createdAt).toLocaleString()}</td>
                      <td>
                        <div className="table-actions">
                          {item?.status === "OPEN" && (
                            <button className="add" onClick={() => openChatModal(item.id)}>
                              Chat
                            </button>
                          )}
                          <button
                            className={item.status !== "OPEN" ? "danger" : "success"}
                            onClick={async () => {
                              if (item.status === "CLOSED") {
                                toast.error("Query already closed")
                              } else {
                                await closeQueryHandler(item?.id);
                              }
                            }
                            }
                          >
                            {item.status === "OPEN" ? "Close Query" : "Closed"}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination pageInfo={data?.pageInfo} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(HelpDesk);
