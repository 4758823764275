import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getPosts, activatePost, deletePost } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
// import AddEdit from "./AddEdit";
import { RxCrossCircled } from "react-icons/rx/index.esm";

const Posts = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
    const [data, setData] = useState({});
    const [openDeletePost, setOpenDeletePost] = useState(false);
    const [postId, setPostId] = useState(null);
    const [pageInfo, setPageInfo] = useState({})
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();
    const searchValue = searchParams.get('search') || '';
    const [type, setType] = useState("Posts");
    const fetch = useCallback(async () => {
        try {
            const apiResponse = await getPosts(search);
            if (apiResponse?.data?.data?.posts?.length > 0) {
                setData({ ...apiResponse?.data?.data });
                setPageInfo({ ...apiResponse?.data?.data?.pageInfo })
            } else {
                setData({});
            }
            stopLoadingRequest();
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.error(error.response.data.message);
                logoutRequest();
            } else if (error?.response?.status === 403) {
                toast.error(error.response.data.message);
            }
            stopLoadingRequest();
        }
    }, [data, search, pathname]);

    useEffect(() => {
        startLoadingRequest();
        fetch();
    }, [pathname]);
    const activateDeactivateBannerHandler = useCallback(async (id, isActive) => {
        try {
            startLoadingRequest();
            await activatePost(id, {
                requestType: isActive ? 0 : 1
            });
            toast.success(
                !isActive
                    ? "Banner activated successfully"
                    : "Banner deactivated successfully"
            );
            const searchParams = new URLSearchParams(search);
            const currentPage = searchParams.get("page") || 1;
            fetch();
            // fetch(`?page=${currentPage}&${searchParams.toString()}`);
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.error(error.response.data.message);
                logoutRequest();
            } else if (error?.response?.status === 403) {
                toast.error(error.response.data.message);
            }
            stopLoadingRequest();
        }
    }, [fetch]);

    const deletePostHandler = useCallback((id) => {
        setOpenDeletePost(true);
        setPostId(id);
    }, [])
    const deletePostById = useCallback(async (id) => {
        try {
            startLoadingRequest();
            await deletePost(id);
            toast.success("Post deleted successfully");
            setOpenDeletePost(false);
            fetch();
        } catch (error) {
            toast.error(error.response.data.message);
            if (error?.response?.status === 401) {
                toast.error(error.response.data.message);
                logoutRequest();
            }
            stopLoadingRequest();
        }
    }, [])


    const searchChangeHandler = useCallback((event) => {
        navigate(`/posts?page=1&search=${encodeURIComponent(event.target.value)}`);
    }, []);

    const resetSearchHandler = useCallback(() => {
        navigate('/posts?page=1&search=');
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            startLoadingRequest();
            fetch();
        }, 1000);
        return () => clearTimeout(timeout);
    }, [pathname, search]);

    return (
        <div className="section">
            <h1>Posts/ Polls</h1>
            <div className="wrapper">
                <div className="table-wrapper">
                    <div className="table-header">
                        <div className="search">
                            <div className="input-wrapper">
                                <input
                                    value={searchValue}
                                    onChange={searchChangeHandler}
                                    placeholder="Search..."
                                />
                                {searchValue && (
                                    <RxCrossCircled onClick={resetSearchHandler} size={18} />
                                )}
                            </div>
                        </div>
                        {/* <AddEdit type={"banner-add"} fetch={fetch} /> */}
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Image</th>
                                <th>Code</th>
                                <th>description</th>
                                <th>Caption</th>
                                <th>Under Review</th>
                                <th>Featured</th>
                                <th>Blocked</th>
                                <th>Total Shares</th>
                                <th>Active/Inactive</th>
                                {type === "Posts" && <th>Reactions</th>}
                                {type === "Polls" && <th>Poll Options</th>}
                                <th>Created at</th>
                                <th>Actions</th>
                            </thead>
                            <tbody>
                                {data?.posts?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                                        <td className="rect-image">
                                            {item.images.length > 0 && <img src={item.images[0]}></img>}
                                            {item.images.length > 1 && <p>{item.images.length - 2} + more</p>}
                                        </td>
                                        <td>{item.code}</td>
                                        <td>{item.description}</td>
                                        <td>{item.caption}</td>
                                        <td>{item.isUnderReview ? "Yes" : "No"}</td>
                                        <td>{item.featuredStatus === null ? "NA" : item.featuredStatus}</td>
                                        <td>{item.blockedStatus ? "Yes" : "No"}</td>
                                        <td>{item.totalShares === null ? "NA" : item.totalShares}</td>
                                        <td>{item.isActive ? "Active" : "Inactive"}</td>
                                        <td>{item.totalReactions}</td>
                                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                                        <td>
                                            <div className="table-actions">
                                                {
                                                    !item.blockedStatus ?
                                                        <>
                                                            <button
                                                                className={item.isActive ? "danger" : "success"}
                                                                onClick={() =>
                                                                    activateDeactivateBannerHandler(
                                                                        item.id,
                                                                        item.isActive
                                                                    )
                                                                }
                                                            >
                                                                {item.isActive ? "Deactivate" : "Activate"}
                                                            </button>
                                                            <button
                                                                className={"danger"}
                                                                onClick={() => deletePostHandler(item.id)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </> : <p>Deleted</p>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination pageInfo={data.pageInfo} />
                </div>
            </div>
            {openDeletePost && (
                <div className="delete-popup">
                    <div className="popup-content">
                        <h2>Are you sure you want to delete this post?</h2>
                        <div className="popup-actions">
                            <button
                                className="success"
                                onClick={() => deletePostById(postId)}
                            >
                                Confirm
                            </button>
                            <button className="danger"
                                onClick={() => setOpenDeletePost(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logoutRequest: actions.auth.logout,
            stopLoadingRequest: actions.loader.stopLoading,
            startLoadingRequest: actions.loader.startLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(Posts);
