import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getFaqs, activateDeactivateFaq } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import Pagination from "../../components/Pagination/Pagination";

const Faqs = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({})
  const { pathname, search } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getFaqs(search);
      if (apiResponse?.data?.data?.faqs?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);
  const activateDeactivateFaqHandler = useCallback(async (id, isActive) => {
    try {
      startLoadingRequest();
      await activateDeactivateFaq(id, { requestType: isActive ? "0" : "1" });
      toast.success(
        !isActive
          ? "Faq activated successfully"
          : "Faq deactivated successfully"
      );
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [fetch]);

  const searchChangeHandler = useCallback((event) => {
    navigate(
      `/faqs?page=1&search=${event.target.value}`
    );
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate(
      `/faqs?page=1&search=`
    );
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  return (
    <div className="section">
      <h1>FAQ's</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
          {/* <div className="search">
        <div className="input-wrapper">
                <input
                  value={
                    search
                      ?.split("?")[1]
                      ?.split("&")
                      ?.filter((el) => el.includes("search"))[0]
                      ?.split("=")[1]
                      ?.trim() || ""
                  }
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {search
                  ?.split("?")[1]
                  ?.split("&")
                  ?.filter((el) => el.includes("search"))[0]
                  ?.split("=")[1]
                  ?.trim() && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
              </div> */}
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Type</th>
                <th>Active/Inactive</th>
                <th>Created at</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.faqs?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td>{item.question}</td>
                    <td>{item.answer}</td>
                    <td>{item.type}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive && (
                          <AddEdit
                            type={`TnE-${item.id}`}
                            data={item}
                            fetch={fetch}
                          />
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateFaqHandler(
                              item.id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data?.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Faqs);
