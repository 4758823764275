import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getFaqs, activateDeactivateFaq, getTransactions } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
// import AddEdit from "./AddEdit";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import Pagination from "../../components/Pagination/Pagination";

const Transactions = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const currentYear = new Date().getFullYear();
  console.log(currentYear)
  const [status, setStatus] = useState("success");
  const [year, setYear] = useState(currentYear);
  const [method, setMethod] = useState("Offline");
  const [pageInfo, setPageInfo] = useState({})
  const { pathname, search } = useLocation();
   const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search") || "";
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getTransactions(search,status,year,method);
      if (apiResponse?.data?.data?.transactions?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname,year,method,status]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);
  const activateDeactivateFaqHandler = useCallback(async (id, isActive) => {
    try {
      startLoadingRequest();
      await activateDeactivateFaq(id, { requestType: isActive ? "0" : "1" });
      toast.success(
        !isActive
          ? "Faq activated successfully"
          : "Faq deactivated successfully"
      );
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [fetch]);

  const searchChangeHandler = useCallback((event) => {
    navigate(`/transactions?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate("/transactions?page=1&search=");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search,year,method,status]);

  return (
    <div className="section">
      <h1>Transaction's</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">

          <div className="input-wrapper">
              <label htmlFor="days">Year:</label>
              <select
                id="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
                <option value="2032">2032</option>
              
              </select>
            </div>
          <div className="input-wrapper">
              <label htmlFor="days">Method:</label>
              <select
                id="method"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="Offline">OFFLINE</option>
                <option value="Online">ONLINE</option>
              
              </select>
            </div>
          <div className="input-wrapper">
              <label htmlFor="days">Status:</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="pending">PENDING</option>
                <option value="success">SUCCESS</option>
              
              </select>
            </div>
                     {/* <div className="search">
                       <div className="input-wrapper">
                         <input
                           value={searchValue}
                           onChange={searchChangeHandler}
                           placeholder="Search..."
                         />
                         {searchValue && (
                           <RxCrossCircled onClick={resetSearchHandler} size={18} />
                         )}
                       </div>
                     </div> */}
                   </div>
          <div className="table-container">
  <table>
    <thead>
      <tr>
        <th>#</th>
        <th>ID</th>
        <th>Order ID</th>
        <th>Status</th>
        <th>Method</th>
        <th>Mode</th>
        <th>Payment ID</th>
        <th>Message</th>
        <th>Amount</th>
        <th>Currency</th>
        <th>Processed At Date</th>
        <th>Created At Date</th>
      </tr>
    </thead>
    <tbody>
      {data?.transactions?.map((item, index) => (
        <tr key={item.id}>
          <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
          <td>{item.id}</td>
          <td>{item.orderId}</td>
          <td>{item.status}</td>
          <td>{item.method}</td>
          <td>{item.mode}</td>
          <td>{item.paymentId}</td>
          <td>{item.message}</td>
          <td>{item.amount}</td>
          <td>{item.currency}</td>
          <td>{item.processedAtDate ? new Date(item.processedAtDate).toLocaleString() : 'N/A'}</td>
          <td>{item.createdAtDate ? new Date(item.createdAtDate).toLocaleString() : 'N/A'}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

          <Pagination pageInfo={data?.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Transactions);
