import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { getUsersReport } from "../../apis";
import * as XLSX from "xlsx"; // Import the xlsx library
import Pagination from "../../components/Pagination/Pagination";
import { mapHeaders,renderRowData,flattenObject, flattenProductsBooking, unwoundProductsBookingData } from "./ReportUtils";
import ReportPagination from "../../components/Pagination/ReportPagination";
const Reports = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  // const [reportType, setReportType] = useState("users");
  let [reportType, setReportType] = useState(
    localStorage.getItem("reportType") || "users" // Load from localStorage or default to "users"
  );
  const [days, setDays] = useState(localStorage.getItem("days") || "7");
  const [mode, setMode] = useState("view");
  const [data, setData] = useState([]);
  const [pageInfo, setPageInfo] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleDownload = async (dataArg, reportTypeArg) => {
   
    // Use the argument `dataArg` if provided; otherwise, fall back to state `data`
     reportType = reportTypeArg || reportType;
    let dataToProcess = Array.isArray(dataArg) ? dataArg : Array.isArray(data) ? data : [];
    const toastId = toast.loading("Preparing your report, please wait...");
     console.log("Data to process:", dataToProcess);
     if (reportType === "ProductsBooking") {
      dataToProcess = unwoundProductsBookingData(dataToProcess);
    }
  
    // console.log("Processed Data:", dataToProcess);

    
    try {
      if (!dataToProcess || dataToProcess.length === 0) {
        toast.update(toastId, {
          render: `No data available for ${reportType} of last ${days} days.`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      }
  
      const headers = mapHeaders(reportType);
  
      const flattenedData = dataToProcess.map((item) =>
        reportType === "ProductsBooking"
          ? flattenProductsBooking(item)
          : flattenObject(item)
      );
  
      const allKeys = new Set();
      flattenedData.forEach((item) => {
        Object.keys(item).forEach((key) => allKeys.add(key));
      });
  
      const finalHeaders = Array.from(allKeys).map((key) => ({
        key,
        label: headers[key] || key,
      }));
  
      const mappedData = flattenedData.map((item) =>
        finalHeaders.reduce((acc, header) => {
          const value = item[header.key];
          acc[header.label] = value;
          return acc;
        }, {})
      );
  
      const ws = XLSX.utils.json_to_sheet(mappedData);
  
      const wrapTextStyle = {
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "left",
        },
      };
  
      const colWidths = finalHeaders.map((header) => {
        const maxLength = Math.min(
          Math.max(
            header.label.length,
            ...mappedData.map((row) =>
              String(row[header.label] || "").length
            )
          ),
          40 // Limit the maximum column width to a reasonable length
        );
        return { wpx: maxLength * 7 };
      });
  
      ws["!cols"] = colWidths;
  
      Object.keys(ws).forEach((cell) => {
        if (ws[cell] && typeof ws[cell].v === "string" && ws[cell].v.includes("\n")) {
          ws[cell].s = wrapTextStyle;
        }
      });
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${reportType.replace("-", " ").toUpperCase()} Report`
      );
  
      XLSX.writeFile(wb, `${reportType}_${days}_days.xlsx`);
  
      toast.update(toastId, {
        render: `Download completed for ${reportType} report of last ${days} days!`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Error while downloading the report.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      console.error("Error downloading the report:", error);
    }
  };
  
  


  const fetchReport = async (page) => {
    const toastId = toast.loading("Fetching report, please wait...");
    try {
      setLoading(true);
      console.log("Fetching report...",mode);
      const response = await getUsersReport(reportType, days,mode, page);
      setPageInfo(response.data?.data?.pageInfo);
      
      switch (reportType) {
        case "users":
          setData(response.data.data.users);
          break;
        case "banners":
          setData(response.data.data.banners);
          break;
        case "blogs":
          setData(response.data.data.blogs);
          break;
        case "privacy-policies":
          setData(response.data.data.privacyPolicies);
          break;
        case "notifications":
          setData(response.data.data.notifications);
          break;
        case "socialPosts":
          setData(response.data.data.socialPosts);
          break;
        case "salonHomePages":
          setData(response.data.data.salons);
          break;
        case "serviceBooking":
        case "completedServiceBooking":
        case "upcomingServiceBooking":
          setData(response.data.data.salonsGuestAppointmentServices);
          break;
        case "posts":
          setData(response.data.data.posts);
          break;
        case "polls":
          setData(response.data.data.polls);
          break;
        case "ProductsBooking":
          setData(response.data.data.orders);
          break;
        default:
          console.error(`Unsupported report type: ${reportType}`);
          toast.update(toastId, {
            render: "Unsupported report type selected.",
            type: "error",
            isLoading: false,
          });
      }
      toast.update(toastId, {
        render: "Report fetched successfully.",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Failed to fetch the report. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      console.error("Error fetching report:", error);
    }
    finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchReport(1);
  }, [mode, reportType, days]);
   // Add dependencies as needed

  useEffect(() => {
    const newHeaders = mapHeaders(reportType);
    setHeaders(newHeaders);
    localStorage.setItem("reportType", reportType);
    localStorage.setItem("days", days);

  }, [reportType,days]);

  const handlePageChange = (page) => {
    fetchReport(page);
  };

  console.log(data)
  return (
    <div className="section">
      <h1>Reports</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header flex-start">
            {/* Mode Dropdown */}
            <div className="input-wrapper">
              <label htmlFor="mode">Mode:</label>
              <select
                id="mode"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value="view">View</option>
                <option value="download">Download</option>
              </select>
            </div>

            {/* Report Type Dropdown */}
            <div className="input-wrapper">
              <label htmlFor="reportType">Report Type:</label>
              <select 
                id="reportType"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <option value="users">User</option>
                <option value="banners">Banner</option>
                <option value="blogs">Blogs</option>
                <option value="privacy-policies">Privacy Policy</option>
                <option value="notifications">Notification</option>
                <option value="socialPosts">Social Post</option>
                <option value="salonHomePages">Salon HomePage</option>
                <option value='serviceBooking'>Booking Service</option>
                <option value='completedServiceBooking'>Completed Booking Service</option>
                <option value='upcomingServiceBooking'>Upcoming Booking Service</option>
                <option value="posts">Post</option>
                <option value="polls">Polls</option>
                <option value="ProductsBooking">Product Booking</option>
              </select>
            </div>

            <div className="input-wrapper">
              <label htmlFor="days">Time Period:</label>
              <select
                id="days"
                value={days}
                onChange={(e) => setDays(e.target.value)}
              >
                <option value="0">Today</option>
                <option value="7">7 days</option>
                <option value="30">30 days</option>
                <option value="90">90 days</option>
                <option value="365">1 year</option>
                <option value="all">All</option>
              </select>
            </div>
            {mode === "download" && (
            <div style={{ marginTop: "20px" }} className="input-wrapper">
                <button
                  onClick={handleDownload}
                  disabled={loading}
                  className="btn-download"
                  style={{
                    backgroundColor: "#4CAF50", // Green background
                    color: "#fff", // White text
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  {loading ? "Loading..." : "Download Report"}
                </button>
            </div>
            )}

            {/* Conditional Time Period Dropdown and Download Button */}
           
            {mode === "view" && (
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      {Object.values(headers).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                        {renderRowData(item, reportType,handleDownload,setData)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {mode === "view" && <ReportPagination pageInfo={pageInfo} onPageChange={handlePageChange} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Reports);
