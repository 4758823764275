import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAdmins, activateDeactivateAdmin } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";
import ChangePassword from "./ChangePassword";
import { RxCrossCircled } from "react-icons/rx/index.esm";

const Admins = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({})
  const navigate = useNavigate()
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAdmins(search);
      if (apiResponse?.data?.data?.admins?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo })
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname, search]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);
  const activateDeactivateAdminHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        await activateDeactivateAdmin(id, { requestType });
        fetch();
        toast.success(
          requestType === 1
            ? "Admin activated successfully"
            : "Admin deactivated successfully"
        );
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    [fetch]
  );


  const searchChangeHandler = useCallback((event) => {
    navigate(`/admins?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate('/admins?page=1&search=');
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);


  console.log(data.pageInfo);



  return (
    <div className="section">
      <h1>Admins List</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={searchValue}
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {searchValue && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
            </div>
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Gender</th>
                <th>Active/Inactive</th>
                <th>Permissions</th>
                <th>Created at</th>
                <th>Last updated at</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.admins?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.gender}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      {item.permissions?.map((el) => el.name)?.join(", ")}
                    </td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    <td>{new Date(item.updatedAt).toLocaleString()}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive ? (
                          <>
                            <AddEdit data={item} fetch={fetch} />
                            <ChangePassword data={item} fetch={fetch} />
                            <button
                              className="danger"
                              onClick={() =>
                                activateDeactivateAdminHandler(item.id, 0)
                              }
                            >
                              Deactivate
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() =>
                              activateDeactivateAdminHandler(item.id, 1)
                            }
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Admins);
